import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { QRCodeSVG } from "qrcode.react";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar, FaClock, FaExclamationCircle, FaCheckCircle, FaApple, FaDownload } from "react-icons/fa";

const PatientVisitationBooking = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [bookingStatus, setBookingStatus] = useState("pending");
  const [error, setError] = useState("");
  const [showQR, setShowQR] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [patientDetails, setPatientDetails] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmButtonState, setConfirmButtonState] = useState({
    text: "Confirm",
    color: "bg-purple-600"
  });

  const timeSlots = [
    "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM",
    "11:00 AM", "11:30 AM", "02:00 PM", "02:30 PM",
    "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM"
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setError("");
  };

  const handleStartTimeSelection = (time) => {
    setSelectedStartTime(time);
    setSelectedEndTime(null);
    setError("");
  };

  const handleEndTimeSelection = (time) => {
    const startIndex = timeSlots.indexOf(selectedStartTime);
    const endIndex = timeSlots.indexOf(time);
    
    const timeDifference = (endIndex - startIndex) * 30;
    
    if (startIndex >= endIndex) {
      setError("End time must be after start time");
      return;
    }

    if (timeDifference > 120) {
      setError("Booking duration cannot exceed 2 hours");
      return;
    }

    setSelectedEndTime(time);
    setError("");
  };

  const handleConfirmDetails = () => {
    if (!patientDetails) {
      setError("Please enter patient name or room number");
      return;
    }
    setIsConfirmed(true);
    setError("");
    setConfirmButtonState({
      text: "Confirmed",
      color: "bg-green-600"
    });
  };

  const handleBooking = () => {
    if (!patientDetails) {
      setError("Please enter patient name or room number");
      return;
    }

    if (!selectedDate || !selectedStartTime || !selectedEndTime) {
      setError("Please select date, start time and end time for your visitation");
      return;
    }

    const booking = {
      id: Math.random().toString(36).substr(2, 9),
      patientDetails,
      date: selectedDate,
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      status: "Confirmed"
    };

    setBookingData(booking);
    setBookingStatus("confirmed");
    setShowQR(true);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-canvas");
    if (canvas) {
      const url = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = `booking-qr-${bookingData.id}.png`;
      link.href = url;
      link.click();
    }
  };

  const addToAppleWallet = () => {
    const passData = {
      formatVersion: 1,
      passTypeIdentifier: "pass.com.hospital.booking",
      serialNumber: bookingData.id,
      teamIdentifier: "TEAM_ID",
      organizationName: "Hospital Name",
      description: "Hospital Visit Booking",
      foregroundColor: "rgb(255, 255, 255)",
      backgroundColor: "rgb(60, 65, 76)",
      generic: {
        primaryFields: [
          {
            key: "booking",
            label: "Booking ID",
            value: bookingData.id
          }
        ],
        secondaryFields: [
          {
            key: "date",
            label: "Date",
            value: new Date(bookingData.date).toLocaleDateString()
          }
        ]
      }
    };

    console.log("Adding to Apple Wallet:", passData);
    alert("This is a demo. In a real app, this would add the pass to Apple Wallet.");
  };

  const StatusBanner = () => (
    <div
      className={`w-full p-4 mb-6 rounded-lg ${bookingStatus === "confirmed" ? "bg-green-100" : "bg-blue-100"} ${bookingStatus === "cancelled" ? "bg-red-100" : ""}`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {bookingStatus === "confirmed" ? (
            <FaCheckCircle className="text-green-500 text-xl mr-2" />
          ) : (
            <FaClock className="text-blue-500 text-xl mr-2" />
          )}
          <span className={`font-semibold ${bookingStatus === "confirmed" ? "text-green-700" : "text-blue-700"}`}>
            {bookingStatus.charAt(0).toUpperCase() + bookingStatus.slice(1)}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-purple-100">
      <nav className="bg-purple-900 text-white p-4 shadow-lg fixed w-full top-0 z-50">
        <div className="container mx-auto text-center">
          <h1 className="text-xl md:text-2xl font-bold">Hospital Visitation System</h1>
        </div>
      </nav>
      
      <div className="pt-20 px-4 sm:px-6 lg:px-8 pb-12">
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl w-full">
          <div className="p-4 md:p-8">
            <div className="mb-6 md:mb-8">
              <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-2">Patient Visitation Booking</h2>
              <p className="text-sm md:text-base text-gray-600">Select your preferred date and time range for the visit (Maximum 2 hours)</p>
            </div>

            <StatusBanner />

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="patientDetails">
                Patient Name or Room Number
              </label>
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                <input
                  type="text"
                  id="patientDetails"
                  value={patientDetails}
                  onChange={(e) => setPatientDetails(e.target.value)}
                  className="flex-1 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm md:text-base"
                  placeholder="Enter patient name or room number"
                  disabled={isConfirmed}
                />
                <button
                  onClick={handleConfirmDetails}
                  disabled={isConfirmed}
                  className={`px-4 py-2 ${confirmButtonState.color} text-white rounded-lg hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 text-sm md:text-base w-full sm:w-auto`}
                >
                  {confirmButtonState.text}
                </button>
              </div>
            </div>

            {isConfirmed && (
              <>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="date">
                    Select Date
                  </label>
                  <div className="relative">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="MMMM d, yyyy"
                      minDate={new Date()}
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm md:text-base"
                      placeholderText="Select date"
                      id="date"
                    />
                    <FaCalendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>
                </div>

                {selectedDate && (
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Select Start Time
                    </label>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                      {timeSlots.map((time, index) => (
                        <button
                          key={index}
                          onClick={() => handleStartTimeSelection(time)}
                          className={`p-2 text-xs md:text-sm rounded-lg transition-colors
                            ${selectedStartTime === time 
                              ? "bg-purple-600 text-white" 
                              : "bg-gray-100 hover:bg-purple-100 text-gray-700"}
                          `}
                        >
                          {time}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {selectedStartTime && (
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Select End Time
                    </label>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                      {timeSlots.map((time, index) => {
                        const startIndex = timeSlots.indexOf(selectedStartTime);
                        const disabled = index <= startIndex || index > startIndex + 4;
                        return (
                          <button
                            key={index}
                            onClick={() => handleEndTimeSelection(time)}
                            disabled={disabled}
                            className={`p-2 text-xs md:text-sm rounded-lg transition-colors
                              ${selectedEndTime === time 
                                ? "bg-purple-600 text-white" 
                                : disabled 
                                  ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                                  : "bg-gray-100 hover:bg-purple-100 text-gray-700"}
                            `}
                          >
                            {time}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}

                {error && (
                  <div className="mb-4 flex items-center text-red-500">
                    <FaExclamationCircle className="mr-2" />
                    <span className="text-xs md:text-sm">{error}</span>
                  </div>
                )}

                <button
                  onClick={handleBooking}
                  className="w-full bg-purple-900 text-white py-3 px-4 rounded-lg hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transform transition-all duration-200 ease-in-out hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed text-sm md:text-base"
                  disabled={bookingStatus === "confirmed"}
                >
                  {bookingStatus === "confirmed" ? "Booking Confirmed" : "Confirm Booking"}
                </button>
              </>
            )}

            {showQR && bookingData && (
              <div className="mt-8 p-4 md:p-6 bg-gray-50 rounded-lg">
                <h3 className="text-base md:text-lg font-semibold text-gray-900 mb-4">Your Booking QR Code</h3>
                <div className="flex justify-center bg-white p-4 rounded-lg shadow-sm">
                  <QRCodeSVG
                    id="qr-canvas"
                    value={JSON.stringify(bookingData)}
                    size={180}
                    level="H"
                    includeMargin={true}
                  />
                </div>
                <p className="mt-4 text-xs md:text-sm text-gray-600 text-center">
                  Please present this QR code during your visit
                </p>
                <div className="mt-4 flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4">
                  <button
                    onClick={addToAppleWallet}
                    className="flex items-center justify-center px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors text-sm md:text-base"
                  >
                    <FaApple className="mr-2" />
                    Add to Apple Wallet
                  </button>
                  <button
                    onClick={downloadQRCode}
                    className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors text-sm md:text-base"
                  >
                    <FaDownload className="mr-2" />
                    Save QR Code
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientVisitationBooking;
